// patch of URL constructor for safari
// This should be there before all js imports
var URL_ = typeof URL === 'function' ? URL : require('url-parse');
window.URL = function(url, base) {
  if (base) {
    return new URL_(url, base)
  } else {
    return new URL_(url)
  }
}
